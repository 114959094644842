import { createRouter, createWebHashHistory } from 'vue-router'
import entrance from '../views/entrance.vue'

const routes = [
  {
    path: '/',
    name: 'Entrance',
    component: entrance
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
