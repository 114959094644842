<template>
  <div class="wrapId">
    <el-dialog @opened="qrCodeCreate" class="downloadAppWrap" :model-value="showAppModal" width="45%" :show-close="false"
      :close-on-click-modal="false" @close="closeAppModal(false)" center>
      <div class="wrapper">
        <div class="leftWrapper">
          <el-image :src="imgSrc"></el-image>
        </div>
        <div class="rightWrapper">
          <div class="closeBtn">
            <el-icon @click="closeAppModal(false)">
              <Close />
            </el-icon>
            <el-icon style="vertical-align: middle">
              <Plus />
            </el-icon>
          </div>
          <div class="title">农资大掌柜手机版</div>
          <div class="ercode">
            <div id="qrCode" ref="qrCode"></div>
          </div>
          <div class="scanCode">
            <div class="first">扫码下载，立即使用</div>
            <div class="second">（仅支持安卓手机）</div>
          </div>
          <div class="jumpOtherSystem">
            <div class="btns" @click="jumpIndex">进入电脑系统</div>
          </div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import imgSrc from '../assets/downloadApp.png'
import QRCode from 'qrcodejs2'
import { Close } from '@element-plus/icons'
export default {
  name: 'downloadApp',
  components: {
    Close
  },
  props: {
    showAppModal: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      qrCodes: '',
      imgSrc,
      checked: false

    }
  },

  methods: {
    jumpIndex() {
      const item = 'http://ahny.ahlssp.net:81/new-agriculture/#/login'
      window.open(item)
    },
    handleSave(v) {
      if (this.checked) {
        window.localStorage.setItem('isShowDownLoadModal', '1')
      } else {
        window.localStorage.removeItem('isShowDownLoadModal')
      }
    },
    qrCodeCreate() {
      this.$nextTick(() => {
        const url = 'http://ahny.ahlssp.net:81/app/#/personalCenter/sharePage'
        const qrcode = new QRCode(this.$refs.qrCode, {
          // text: 'xxxx', // 需要转换为二维码的内容
          text: url // 需要转换为二维码的内容
          // width: ,
          // height: 180
          // colorDark: "#000000", //二维码前景色
          // colorLight: "#ffffff", //二维码背景色
          // correctLevel: QRCode.CorrectLevel.H, //容错级别
        })
        this.qrCodes = qrcode
      })
    },
    closeAppModal(flag) {
      this.$emit('closeAppModal', false, flag)
      this.qrCodes = ''
      document.getElementById('qrCode').innerHTML = ''
    }
  }
}
</script>
<style>
#qrCode img{
  width:180px !important;
  height:180px !important;
}
</style>
<style scoped  lang="scss">
:deep(.el-dialog__header) {

  display: none;
  height: 0px;
  padding: 0px !important;
}

:deep(.el-dialog__body) {
  padding: 0px !important;
}

:deep(.el-image) {
  display: block;
}

.wrapper {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;

  img {
    display: block;
  }

  .leftWrapper,
  .rightWrapper {
    width: 50%;
  }

  .rightWrapper {
    padding: 20px;
    box-sizing: border-box;
    // display: flex;
    // flex-direction: column;
    // justify-content: space-between;

    .ercode {
      width: 100%;
      height: 180px;
      margin: 15px 0px 15px 0px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .closeBtn {
      // display: flex;
      // justify-content: flex-end;
      position:absolute;
      right:10px;
      top:20px;
      font-size: 30px;
      color: #90939A;
      font-weight: bold;
    }

    .title {
      font-size: 24px;
      font-weight: 700;
      text-align: center;
      color: #516690;
    }

    .first {
      font-size: 20px;
      font-weight: 500;
      text-align: center;
      color: #36b065;
      margin-bottom: 8px;
    }

    .second {
      font-size: 16px;
      font-weight: 400;
      text-align: center;
      color: #90939a;

    }

    .jumpOtherSystem {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px 0px 10px 0px;

      .btns {
        width: 200px;
        height: 45px;
        background: linear-gradient(0deg, #2f80ed 0%, #56ccf2 100%);
        border-radius: 24px;
        font-size: 20px;
        font-weight: 700;
        text-align: center;
        color: #ffffff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
      }
    }

    .gouxuan {
      display: flex;
      justify-content: center;
      align-items: center;

    }

    .curStyle {
      font-size: 16px;
      font-weight: 400;
      color: #90939a;

    }
  }
}

.header {
  padding: 0px !important;
  height: 0px;
  margin: 0px !important;
}
</style>
