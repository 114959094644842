<template>
  <div class="entrance">
    <div class="entrance__top">
      <div class="entrance__top__title">安徽省农资服务便民导航（农药台账系统门户）</div>
      <!-- <div class="entrance__top__right"><span class="p_r20 cupointer" @click="jumpPath(regulatory)">后台监管系统</span> -->
      <!-- <span class="cupointer" @click="jumpPath(official)">官方网站</span> -->
      <!-- </div> -->
    </div>
    <div class="entrance__sub">
      <div class="entrance__sub__child">农药系统入口</div>  
      <!-- http://ahny.ahlssp.net:81/supervise/#/homePage -->
      <div class="entrance__top__right"><a href="http://ahny.ahlssp.net:81/supervise/#/homePage" target="_blank"  class="p_r20 cupointer">农药监管平台首页</a>
      </div>
    </div>
    <div class="entrance__msg">
      <div class="entrance__msg__title">农药经营台账系统使用须知：</div>
      <div class="entrance__msg__child">1、登录名：请使用《农药经营许可证》编号内的11位数字作为“登录名”登录系统；</div>
      <div class="entrance__msg__child">2、密码：请在系统内自行找回密码；</div>
      <div class="entrance__msg__child">3、如有疑问请进入系统后，关注微信公众号联系客服咨询；</div>
      <div class="entrance__msg__child">4、“零售商”和“批发商”系统不同，请根据具体经营业务选择（如下）</div>
    </div>
    <div class="entrance-downlad">
      <img src="../assets/dowload_bg.png" class="mrl" />推荐谷歌浏览器，使用台账系统更稳定！<span class="downloads"
        @click="jumGoogele">立即下载<img class="dwl-btn" src="../assets/dowload_icon.png" /></span>
    </div>
    <div class="entrance__content">
      <div :class="{ 'entrance__content__child': true, m_r60: index == 0 }" :style="{ 'background': item.color }"
        v-for="(item, index) in list" :key="index" @click="jumpPath(item.link)">
        <div class="entrance__content__title">{{ item.name }}</div>
        <div class="entrance__content__entry">{{ item.oparate }}</div>
      </div>
    </div>

    <div class="entrance__other">
      <div :class="{ 'entrance__other__child': true, m_r60: index == 0 }" :style="{ 'background': item.color }"
        v-for="(item, index) in otherList" :key="index" @click="jumpPath(item.link)">
        <div class="entrance__other__title">{{ item.name }}</div>
        <div class="entrance__other__entry">{{ item.oparate }}</div>
      </div>
    </div>
    <!-- <div class="entrance__otherSub">种子系统入口</div> -->
    <!-- <div class="entrance__other entrance__seed">
           <div :class="{'entrance__other__child':true,m_r60:index==0}" :style="{'background': item.color}" v-for="(item,index) in seedList" :key="index" @click="jumpPath(item.link)">
              <div class="entrance__other__title">{{item.name}}</div>
              <div class="entrance__other__entry">{{item.oparate}}</div>
           </div>
        </div> -->
    <!--  zuoyou-->
    <div class="gzh">
      <div class="footer-bg">
        <img src="../assets/footer_bgs1.png" />
        <div class="gzh-left">
          <div class="gz">关注微信公众号 </div>
          <div class="smbz">扫一扫二维码，获取帮助</div>
        </div>
      </div>
    </div>
    <div class="entrance-page-footer">主办单位：智秾信息发展股份有限公司&nbsp;&nbsp;<img src="../assets/footer_icon.png" />&nbsp;&nbsp;<a
        class="hover-s" target="_blank" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=31010702007326"
        style="display:inline-block;text-decoration:none;color:#1A1E2D;">沪公网安备：31010702007326号</a>&nbsp;&nbsp;<a
        class="hover-s" target="_blank" style="display:inline-block;text-decoration:none;color:#1A1E2D;"
        href="https://beian.miit.gov.cn/">沪ICP备2021002783号-5</a></div>
  </div>
  <!-- 下载app -->
  <download-app @closeAppModal="closeAppModal" :showAppModal="showAppModal"></download-app>
</template>
<script>
import downloadApp from './downloadApp.vue'

export default {
  name: 'Entrance',
  components: {
    downloadApp
  },
  data() {
    return {
      showAppModal: false
    }
  },
  setup() {
    // const useInitData = () => {
      const list = [{ name: '零售台账系统（只零售）', oparate: '点击进入', color: '#EE7894', link: 'http://ahny.ahlssp.net:81/new-agriculture/#/login' }, { name: '批发商台账系统（批发、批发＋零售）', oparate: '点击进入', color: '#7373F2', link: 'http://ahny.ahlssp.net:81/dealer/#/homePage' }]
      const otherList = [{ name: '包装废弃物回收系统', oparate: '点击进入', color: '#6FCEF1', link: 'http://ahny.ahlssp.net:81/recycle/#/login' }, { name: '农药使用系统（种植户）', oparate: '点击进入', color: '#E5B859', link: 'http://ahny.ahlssp.net:81/use/#/login' }]
      const regulatory = 'http://ahny.ahlssp.net:81/supervise/#/login'
      const official = 'http://ahny.ahlssp.net:81/supervise/#/homePage'
      const seedList = [{ name: '种子备案系统', oparate: '点击进入', color: '#70B604', link: 'http://202.127.42.47:8015/Admin.aspx#' }]
      // return { list, otherList, regulatory, official, seedList }
  // }
    const jumGoogele = () => {
      window.location.href = 'https://image.cert.agrisaas.cn/%E8%B0%B7%E6%AD%8C%E6%B5%8F%E8%A7%88%E5%99%A8.exe'
    }
    // const jumpPage = () => {
    //   window.open("https://beian.miit.gov.cn/")
    // }
    // const { list, otherList, regulatory, official, seedList } = useInitData()
    return { list, otherList, jumGoogele, regulatory, official, seedList }
  },
  methods: {
    closeAppModal() {
      this.showAppModal = false
    },
    downloadAppHandle() { //  下载app
      this.showAppModal = true
    },
    jumpPath(item) {
      if (item === 'http://ahny.ahlssp.net:81/new-agriculture/#/login') {
        console.log('ceshi')
        this.showAppModal = true
      } else {
        window.open(item)
      }
    }
  }
}
</script>
<style lang="scss">
.entrance {
  width: 1200px;
  min-width: 1200px;
  margin: 20px auto;

  .jump-page {
    cursor: pointer;
  }

  .m_r60 {
    margin-right: 60px;
  }

  .p_r20 {
    padding-right: 20px;
  }

  .cupointer {
    cursor: pointer;
  }

  &__top {
    overflow: hidden;

    &__title {
      font-size: 28px;
      font-weight: 400;
      color: #333333;
      margin-bottom: 20px;
    }

    &__right {
      font-size: 16px;
      font-weight: none;
      // float: right;
    }
  }

  &__sub {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 16px;
    display: flex;
    align-items: center;

    &__child {
      flex: 1;
    }

    .entrance__top__right {
      flex: 1;
      font-weight: normal;
      text-align: right;
      text-decoration: underline;
    }
  }

  &__msg {
    width: 100%;
    border: 1px solid #bcbcbc;
    padding: 16px 34px;
    box-sizing: border-box;
    font-size: 18px;
    font-weight: 400;
    color: #333333;

    &__title {
      line-height: 40px;
    }

    &__child {
      line-height: 30px;
    }
  }

  &__content {
    display: flex;
    margin: 20px 0 40px 0;

    &__child {
      flex: 1;
      // height:205px;
      height: 205px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    &__title {
      font-size: 30px;
      font-weight: 400;
      color: #ffffff;
    }

    &__entry {
      font-size: 24px;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
    }
  }

  &__otherSub {
    font-size: 24px;
    font-weight: bold;
    color: #333333;
    margin: 20px 20px 20px 0;
  }

  &__seed {
    width: 630px;
  }

  &__other {
    display: flex;

    &__child {
      flex: 1;
      height: 130px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    &__title {
      font-size: 30px;
      font-weight: 400;
      color: #ffffff;
    }

    &__entry {
      font-size: 24px;
      font-weight: 400;
      color: #ffffff;
      line-height: 36px;
    }
  }

  .entrance-downlad {
    background: #eff3ff;
    border-radius: 21px;
    padding: 10px 10px;
    margin: 10px 0px;
    font-size: 16px !important;
    display: flex;
    // justify-content: center;
    align-items: center;
  }

  .mrl {
    margin-right: 10px;
  }

  .downloads {
    cursor: pointer;
    background: #6a8de7;
    border-radius: 21px;
    font-weight: bold;
    color: #ffffff;
    padding: 5px 15px;
    font-size: 14px !important;
    display: flex;
    // justify-content: center;
    align-items: center;
  }

  .dwl-btn {
    margin-left: 3px;
  }

  .entrance-page-footer {
    width: 100%;
    font-weight: bold;
    color: #1a1e2d;
    // text-align:center;
    padding: 20px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .gzh {
    display: flex;

    .gzh-left {
      display: flex;
      align-items: center;
      flex-direction: column;
    }
  }

  .gz {
    font-size: 30px;
    font-weight: bold;
    color: #344ca9;
  }

  .smbz {
    font-size: 18px;
    font-weight: bold;
    color: #1a1e2d;
    line-height: 29px;
  }

  .gzh {
    position: relative;
    margin: 30px 0px;

    .footer-bg img {
      width: 100%;
    }

    width: 100%;

    .gzh-left {
      position: absolute;
      top: 0px;
      height: 215px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45%;

      .smbz {
        margin-top: 30px;
      }
    }

    // height:250px;
    // border:1px solid red;
    // margin:0px;
    // background: url('../assets/footer_bgs.png') no-repeat;
    // background-size:100% 100%;
    // background-attachment:fixed;
    /* 加载背景图 */
    /* 背景图垂直、水平均居中 */
    // background-position: 100% 100%;
    /* 背景图不平铺 */
    // background-repeat: no-repeat;
    /* 当内容高度大于图片高度时，背景图像的位置相对于viewport固定 */
    // background-attachment: fixed;
    /* 让背景图基于容器大小伸缩 */
    // background-size: cover;
    /* 设置背景颜色，背景图加载过程中会显示背景色 */
    // background-color: #464646;
  }

  .hover-s {
    padding-bottom: 1px;
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }

  .hover-s:hover {
    border-bottom: 1px solid #1a1e2d;
  }
}
</style>
